<template>
  <div class="he-r message-page">
    <el-tabs class="el-tabs-other" v-model="activeNames">
      <el-tab-pane label="全部消息" name="first">
        <span slot="label"><i class="ismessge"></i> 全部消息</span>
        <div class="message-list-box">
          <ul class="message-list">
            <li v-for="item in messageList" :key="item.index">
              <span
                class="person-icons"
                :style="{ background: 'url(' + item.icon + ') no-repeat center', backgroundSize: 'cover' }"
              ></span>
              <div class="message">
                <h5>
                  <span class="tit">{{ item.name }}</span>
                  <span class="time">{{ item.time }}</span>
                </h5>
                <div class="message-con">
                  <p>{{ item.text }}</p>
                  <i class="num-i" v-if="item.me === true">{{ item.num }}</i>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </el-tab-pane>
      <el-tab-pane name="second">
        <span slot="label"><i class="ismessge"></i> 系统信息</span>
      </el-tab-pane>
      <el-tab-pane name="third">
        <span slot="label"><i class="ismessge"></i> 私信邮件</span>

        <div class="child-tab">
          <el-tabs class="el-tabs-other" v-model="childactive">
            <el-tab-pane label="已收到" name="first">
              <div class="message-list-box">
                <ul class="message-list">
                  <li v-for="item in messageList" :key="item.index" @click="mainInfo">
                    <span
                      class="person-icons"
                      :style="{ background: 'url(' + item.icon + ') no-repeat center', backgroundSize: 'cover' }"
                    ></span>
                    <div class="message">
                      <h5>
                        <span class="tit">{{ item.name }}</span>
                        <span class="time">{{ item.time }}</span>
                      </h5>
                      <div class="message-con">
                        <p>{{ item.text }}</p>
                        <i class="num-i" v-if="item.me === true">{{ item.num }}</i>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </el-tab-pane>
            <el-tab-pane label="已发送" name="second">
              2
            </el-tab-pane>
            <el-tab-pane label="写邮件" name="third">
              <div class="mail-form">
                <el-form ref="form" :model="form" label-width="90px">
                  <el-form-item label="收件人">
                    <el-input v-model="form.name" placeholder="选择关注人">
                      <el-button slot="suffix" class="edit-btn"><i class="edit"></i>选择关注人</el-button>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="主题">
                    <el-input
                      type="text"
                      placeholder="请输入内容"
                      v-model="form.zt"
                      maxlength="30"
                      show-word-limit
                    ></el-input>
                  </el-form-item>
                  <el-form-item class="no-bg" label="正文">
                    <div class="zw-box">
                      <div class="upload-box">
                        <el-upload
                          class="upload-demo upload-img"
                          action="https://jsonplaceholder.typicode.com/posts/"
                          :on-preview="handlePreviewImg"
                          :on-remove="handleRemoveImg"
                          :before-remove="beforeRemoveImg"
                          multiple
                          :on-exceed="handleExceedImg"
                          :file-list="imgList"
                        >
                          <el-button size="small" class="upload-btn"><i class="up-img"></i>插入图片</el-button>
                        </el-upload>

                        <el-upload
                          class="upload-demo upload-file"
                          action="https://jsonplaceholder.typicode.com/posts/"
                          :on-preview="handlePreview"
                          :on-remove="handleRemove"
                          :before-remove="beforeRemove"
                          multiple
                          :on-exceed="handleExceed"
                          :file-list="fileList"
                        >
                          <el-button size="small" class="upload-btn"><i class="up-file"></i>插入图片</el-button>
                        </el-upload>
                      </div>
                      <div class="text-area">
                        <el-input type="textarea" v-model="form.desc"></el-input>
                      </div>
                      <div class="mail-btn-box">
                        <span>正文字数：0</span>
                        <div class="fb-btn-box">
                          <el-button class="cg-btn">存草稿</el-button>
                          <el-button class="fa-btn">发布</el-button>
                        </div>
                      </div>
                    </div>
                  </el-form-item>
                </el-form>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      childactive: 'first',
      activeNames: 'first',
      messageList: [
        {
          icon: require('../../assets/images/person.png'),
          name: '南平市非物质文化遗产保护中心',
          text: '南平市非物质文化遗产传承人认证审核通知',
          time: '2020年10月29日',
          num: '12',
          me: true
        },
        {
          icon: require('../../assets/images/person.png'),
          name: '南平市非物质文化遗产保护中心',
          text: '南平市非物质文化遗产传承人认证审核通知',
          time: '2020年10月29日',
          num: '12',
          me: false
        },
        {
          icon: require('../../assets/images/person.png'),
          name: '南平市非物质文化遗产保护中心',
          text: '南平市非物质文化遗产传承人认证审核通知',
          time: '2020年10月29日',
          num: '12',
          me: false
        },
        {
          icon: require('../../assets/images/person.png'),
          name: '南平市非物质文化遗产保护中心',
          text: '南平市非物质文化遗产传承人认证审核通知',
          time: '2020年10月29日',
          num: '12',
          me: false
        },
        {
          icon: require('../../assets/images/icon3.png'),
          name: '南平市非物质文化遗产保护中心',
          text: '南平市非物质文化遗产传承人认证审核通知',
          time: '2020年10月29日',
          num: '12',
          me: true
        },
        {
          icon: require('../../assets/images/icon4.png'),
          name: '南平市非物质文化遗产保护中心',
          text: '南平市非物质文化遗产传承人认证审核通知slfjsfshgklhkdfjkdfkkgj',
          time: '2020年10月29日',
          num: '12',
          me: true
        },
        {
          icon: require('../../assets/images/icon5.png'),
          name: '南平市非物质文化遗产保护中心',
          text: '南平市建瓯人，1956年8月生，国家级非物质文化遗产扩展项目中幡就开始的看法和释放',
          time: '2020年10月29日',
          num: '12',
          me: true
        }
      ],
      form: {
        desc: '请输入正文内容',
        name: '',
        zt: '南平市非物质文化遗产传承人认证审核通知'
      },
      fileList: [
        {
          name: 'food.doc',
          url:
            'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        },
        {
          name: 'food2.doc',
          url:
            'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        }
      ],
      imgList: [
        {
          name: 'food.jpeg',
          url:
            'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        },
        {
          name: 'food2.jpeg',
          url:
            'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
        }
      ]
    }
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePreview(file) {
      console.log(file)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + imgList.length} 个文件`
      )
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleRemoveImg(file, imgList) {
      console.log(file, imgList)
    },
    handlePreviewImg(file) {
      console.log(file)
    },
    handleExceedImg(files, imgList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + imgList.length} 个文件`
      )
    },
    beforeRemoveImg(file, imgList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    mainInfo() {
      this.$router.replace('/mailInfo')
    }
  }
}
</script>
